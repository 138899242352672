<template>
	<a @click.prevent="visitBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
import { BookingEngineFactory } from '@becurious/mews';
const { locale } = useI18n();
const config = useRuntimeConfig();
const bookingEngine = useState('bookingEngine', () => {});

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

const visitBookingEngine = (options) => {
	if (!bookingEngine.value) {
		return null;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	return bookingEngine.value.visitUrl(options);
};

onMounted(() => {
	if (!bookingEngine.value) {
		const factory = new BookingEngineFactory({
			hotelID: '6fa53795-159a-497e-aa6b-aa8100def078',
			primaryColor: '#567',
			gtmID: config.public.googleTagManagerId,
			mode: 'widget',
		});
		bookingEngine.value = factory.createBookingEngine();
	}
});
useHead({
	script: [
		{
			hid: 'booking-engine',
			src: 'https://api.mews.com/distributor/distributor.min.js',
		},
	],
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
